/**
 * Component for a single dataviz in the data hub.
 */

import * as React from "react";
import { useRef, useState } from "react";
import DatavizLinks from "./dataviz-links";
import { extractVizId, extractVizType } from "./lib/extractVizId";

const vizSettings = "?1271209";

function getScrollPosition(el: HTMLAnchorElement): number {
  return el.getBoundingClientRect().top + window.scrollY;
}

const Dataviz = ({ item, flourishIsLoaded }) => {
  const placeholderRef = useRef<HTMLDivElement | null>();
  const anchorRef = useRef<HTMLAnchorElement | null>();

  const [hasRendered, setHasRendered] = useState(false);

  React.useEffect(() => {
    if (placeholderRef.current != null && flourishIsLoaded && !hasRendered) {
      window.Flourish.loadEmbed(placeholderRef.current);
      setHasRendered(true);
    }
    if (window.location.hash == `#${item.dataviz._meta.uid}`) {
      window.setTimeout(() => {
        window.scrollTo({
          top: getScrollPosition(anchorRef.current),
          behavior: "smooth",
        });
      }, 1000);
    }
  }, [flourishIsLoaded, hasRendered]);

  const vizId =
    item.dataviz.oembed && extractVizId(item.dataviz.oembed.embed_url);
  const vizType =
    item.dataviz.oembed && extractVizType(item.dataviz.oembed.embed_url);
  return (
    <li className="data-hub__dataviz" id={item.dataviz._meta.uid}>
      <a ref={anchorRef} className="data-hub__dataviz__anchor"></a>
      {item.dataviz.oembed && (
        <div
          ref={placeholderRef}
          className="flourish-embed flourish-chart"
          data-src={`${vizType}/${vizId}${vizSettings}`}
        ></div>
      )}
      {!item.dataviz.oembed && item.dataviz.image && (
        <img src={item.dataviz.image.url} alt={item.dataviz.title[0].text} />
      )}
      <DatavizLinks item={item} />
    </li>
  );
};
export default Dataviz;
