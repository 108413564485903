/**
 * Listing of all data visualizations in the data hub.
 */

import * as React from "react";
import { ChangeEvent } from "react";
import { useState, useEffect, useRef } from "react";
import Dataviz from "./dataviz";

declare global {
  interface Window {
    Flourish: any;
  }
}

interface PrismicRichText {
  text: string;
}

interface PrismicAuthor {
  author: {
    name: PrismicRichText[];
  };
}

interface PrismicDataViz {
  dataviz: {
    _meta: {
      tags: string[];
      uid: string[];
      firstPublicationDate: string;
    };
    title: PrismicRichText;
    image: {
      url: string;
    };
    data_link: string;
    oembed: {
      provider_name: string;
      provider_url: string;
      type: string;
      version: string;
      description: string;
      html: string;
      width: number;
      height: number;
      embed_url: string;
    };
  };
}

interface PrismicImage {
  url: string;
  alt?: string;
  copyright?: string;
}

interface PrismicArticle {
  node: {
    _meta: {
      uid: string;
      tags: string[];
    };
    title: PrismicRichText;
    featured_image: PrismicImage;
    description: PrismicRichText;
    authors: PrismicAuthor[];
  };
}

interface PrismicVitalSigns {
  node: {
    _meta: {
      uid: string;
      tags: string[];
    };
    title: PrismicRichText;
    featured_image: PrismicImage;
    description: PrismicRichText;
    authors: PrismicAuthor[];
  };
}

type TagOptions = string[];

type PrismicDataHubResults = PrismicDataViz[];

const buildArticleLink = (article: PrismicArticle): string =>
  `https://vitalcitynyc.org/articles/${article.node._meta.uid}`;
const buildVitalSignsLink = (vitalSigns: PrismicVitalSigns): string =>
  `https://vitalcitynyc.org/vital_signs/${vitalSigns.node._meta.uid}`;

const DataVizList = ({ tagOptions }) => {
  const scriptRef = useRef<HTMLScriptElement | null>();
  const [results, setResults] = useState<PrismicDataViz[]>([]);
  const [tag, setTag] = useState<string>(null);
  const [fetching, setFetching] = useState<boolean>(false);
  const [flourishScriptLoaded, setFlourishScriptLoaded] =
    useState<boolean>(false);

  const fetchData = async (tag: string): Promise<void> => {
    if (fetching) return;
    setFetching(true);
    try {
      const response = await fetch(`/data_hubs/filter?tag=${tag}`);
      if (response.ok) {
        const results = await response.json();
        if (results) {
          setResults(results);
        } else {
          setResults([]);
        }
        setFetching(false);
      } else {
        setFetching(false);
        throw response.status;
      }
    } catch (e) {
      console.error(`error: ${e}`);
    }
  };

  function updateHistory(tag: string) {
    if (tag && tag.length) {
      const params = new URLSearchParams(window.location.search);
      params.set("tag", tag);
      window.history.pushState(
        {},
        "",
        `${window.location.pathname}?${params.toString()}${
          window.location.hash
        }`,
      );
    } else {
      window.history.pushState(
        {},
        "",
        `${window.location.pathname}${window.location.hash}`,
      );
    }
  }

  // initialize the component - set query from url param, fetch data.
  useEffect(() => {
    window.Flourish = { disable_autoload: true };
    const params = new URLSearchParams(window.location.search);
    const tag = params.get("tag");
    setTag(tag ? tag : "");

    // prevent a race condition where flourish script is not loaded when charts try to render
    const flourishScriptPromise = new Promise((resolve, reject) => {
      scriptRef.current = document.createElement("script");
      scriptRef.current.src =
        "https://public.flourish.studio/resources/embed.js";
      scriptRef.current.onload = resolve;
      scriptRef.current.onerror = reject;
      document.body.appendChild(scriptRef.current);
    });

    flourishScriptPromise.then(() => {
      setFlourishScriptLoaded(true);
    });
  }, []);

  useEffect(() => {
    updateHistory(tag);
    if (tag !== null) {
      fetchData(tag);
    }
    return function cleanup() {
      setFetching(false);
    };
  }, [tag]);

  return (
    <div className="data-hub__charts">
      <h1 className="homepage__section-title">Charts By Topic</h1>
      <div className="row">
        <div className="data-hub__filters">
          <a
            href="#"
            className={`data-hub__filters__link ${
              !tag || !tag.length ? "data-hub__filters__link--selected" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              setTag("");
            }}
          >
            <div>All</div> <div className="arrow"></div>
          </a>
          {tagOptions.map((option) => (
            <a
              key={option}
              href="#"
              className={`data-hub__filters__link ${
                tag === option ? "data-hub__filters__link--selected" : ""
              }`}
              onClick={(e) => {
                e.preventDefault();
                setTag(option);
              }}
            >
              <span>{option}</span> <span className="arrow"></span>
            </a>
          ))}
        </div>
        <ul className="data-hub__dataviz-list">
          {results.map((item, i) => (
            <Dataviz
              item={item}
              flourishIsLoaded={flourishScriptLoaded}
              key={item.dataviz.title[0].text.split(" ").join("-") + i}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DataVizList;
