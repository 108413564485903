import React from "react";
import { createRoot } from "react-dom/client";
import SearchIndex from "./search-index";

document.addEventListener("DOMContentLoaded", () => {
  const searchContainer = document.getElementById("search-app");
  if (searchContainer) {
    createRoot(searchContainer).render(<SearchIndex />);
  }
});
