/**
 * This function scans an article for footnote spans, replaces each with a link, and appends
 * the footnote content to the end of the article.
 */

document.addEventListener("DOMContentLoaded", function () {
  var article = document.body.querySelector(".js-article-body");
  var footnoteSpans = document.body.querySelectorAll("[data-footnote]");

  if (!article || !footnoteSpans.length) {
    return;
  }

  var authorSnippet = article.querySelector(".js-author-snippet");
  var notes = pullFootNotes(footnoteSpans);
  var els = notes.map(createNoteElement);
  var footnotesContainer = createFootnotesContainer(els);

  article.insertBefore(footnotesContainer, authorSnippet);
});

/**
 *
 * @param {Array<HTMLDivElement>} els
 * @returns {HTMLDivElement}
 */
function createFootnotesContainer(els) {
  var footnotesContainer = document.createElement("div");
  footnotesContainer.classList.add("article-footnotes");

  for (var i = 0; i < els.length; i++) {
    var el = els[i];
    footnotesContainer.appendChild(el);
  }
  return footnotesContainer;
}

/**
 * Copy footnote spans to an array to be appened at end of article
 * replace original spans with anchor links.
 *
 * @param {HTMLCollection<HTMLSpanElement>} footnoteSpans
 * @returns {Array<HTMLSpanElement>}
 */
function pullFootNotes(footnoteSpans) {
  var notes = [];

  for (var i = 0; i < footnoteSpans.length; i++) {
    var footnote = footnoteSpans[i];
    notes.push(footnote.cloneNode(true));

    // create the link
    var t = document.createTextNode(`${i + 1}`);
    var a = document.createElement("a");
    a.appendChild(t);
    a.href = `#footnote-${i + 1}`;
    var sup = document.createElement("sup");
    sup.appendChild(a);

    // clear the content and replace with the link
    footnote.parentNode.replaceChild(sup, footnote);
  }
  return notes;
}

/**
 *
 * @param {HTMLSpanElement} note
 * @param {number} i
 * @returns {HTMLDivElement}
 */
function createNoteElement(note, i) {
  var div = document.createElement("div");
  div.classList.add("article-footnote");
  var a = document.createElement("a");
  a.id = `footnote-${i + 1}`;
  a.classList.add("anchor-link");
  div.appendChild(a);
  var content = document.createTextNode(`${i + 1}. `);
  div.appendChild(content);
  div.appendChild(note);
  return div;
}
