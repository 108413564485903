import textBalancer from "text-balancer";

window.triggerEvent = (event_name) => {
  const event = new Event(event_name);
  window.dispatchEvent(event);
};

/*
 * For downloading images from other domains
 * see: https://stackoverflow.com/questions/49474775/chrome-65-blocks-cross-origin-a-download-client-side-workaround-to-force-down?answertab=votes#tab-top
 */

function forceDownload(blob, filename) {
  var a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
}

// Current blob size limit is around 500MB for browsers
async function downloadResource(url, filename) {
  if (!filename) filename = url.split("\\").pop().split("/").pop();
  try {
    var response = await fetch(url, {
      headers: new Headers({
        Origin: location.origin,
      }),
      mode: "cors",
    });
    if (response.status >= 400) {
      throw new Error(
        `There was an error downloading the image. Response code: ${response.status}`,
      );
    }
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);
    forceDownload(blobUrl, filename);
    return true;
  } catch (e) {
    console.error(e);
    alert(e);
    return false;
  }
}

window.downloadResource = downloadResource;
window.textBalancer = textBalancer;

export { downloadResource };
