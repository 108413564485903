/**
 * Links to share, view, download an image or underlying data of a data viz.
 *
 * TODO: Break down into smaller components to improve readability.
 */

import * as React from "react";
import { useState, useCallback, useMemo } from "react";
import Icon from "../../assets/images/new-tab-icon.svg";
import { extractVizId, extractVizType } from "./lib/extractVizId";
import { downloadResource } from "../utilities/index.js";
import FBIcon from "../../assets/images/facebook.svg";
import TWIcon from "../../assets/images/twitter.svg";
import LIIcon from "../../assets/images/linkedin.svg";

const DatavizLinks = ({ item }) => {
  const [imageIsDowloading, setImageIsDownloading] = useState<boolean>(false);

  const downloadImage = useCallback(
    async (url, filename) => {
      setImageIsDownloading(true);
      await downloadResource(url, filename);
      setImageIsDownloading(false);
    },
    [item],
  );

  const shareURL = useMemo(() => {
    const url = `${process.env.APPLICATION_HOST}/proxy_link/data_hub/${item.dataviz._meta.uid}`;
    return encodeURIComponent(url);
  }, [item]);

  // define data link
  const dataLink = item.dataviz.data_link ? (
    <a href={item.dataviz.data_link}>Data</a>
  ) : null;

  // define image link
  let imageLink = null;
  if (item.dataviz.oembed) {
    const vizId = extractVizId(item.dataviz.oembed.embed_url);
    const vizType = extractVizType(item.dataviz.oembed.embed_url);
    imageLink = (
      <a
        className={imageIsDowloading ? "link--disabled" : ""}
        href={`/flourish_screenshots/${vizId}?type=${vizType}`}
        onClick={(e) => {
          e.preventDefault();
          downloadImage(
            `/flourish_screenshots/${vizId}?type=${vizType}`,
            `${item.dataviz.title[0].text}.png`,
          );
        }}
      >
        Chart Image
      </a>
    );
  } else if (item.dataviz.image) {
    imageLink = (
      <a
        className={imageIsDowloading ? "link--disabled" : ""}
        href={item.dataviz.image.url}
        onClick={(e) => {
          e.preventDefault();
          if (imageIsDowloading) {
            return;
          }
          downloadResource(
            item.dataviz.image.url,
            `${item.dataviz.title[0].text}.png`,
          );
        }}
      >
        Chart Image
      </a>
    );
  }
  const viewLink = (
    <a
      className="data-hub__dataviz__view-link"
      target="_blank"
      href={`/dataviz/${item.dataviz._meta.uid}`}
    >
      <img src={Icon} />
    </a>
  );

  return (
    <>
      <div className="data-hub__dataviz-links">
        <div className="data-hub__download-links">
          <div className="data-hub__dataviz__data-link">
            Download: {dataLink && dataLink}
            {dataLink && imageLink && ", "}
            {imageLink && imageLink}
          </div>
        </div>
        <div className="data-hub__share">
          <span>SHARE</span>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?display=page&u=${shareURL}`}
            target="_blank"
          >
            <img className="icon" src={FBIcon} />
          </a>
          <a
            href={`http://www.twitter.com/share?url=${shareURL}`}
            target="_blank"
          >
            <img className="icon" src={TWIcon} />
          </a>
          <a
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareURL}`}
            target="_blank"
          >
            <img className="icon" src={LIIcon} />
          </a>
        </div>
      </div>
      {viewLink}
    </>
  );
};

export default DatavizLinks;
