document.addEventListener("DOMContentLoaded", function () {
  if (
    typeof Swiper == "undefined" ||
    document.getElementsByClassName("sonic-essay__swiper").length == 0
  ) {
    return;
  }

  window.se_controller = "time";
  const params = {
    direction: "vertical",
    allowTouchMove: false,
    mousewheel: { enabled: false },
    touchStartForcePreventDefault: true,
    speed: 600,
  };
  let swiper = new Swiper(".sonic-essay__swiper", params);
  attachEvents(swiper);

  window.swiper = swiper;

  const container = document.getElementById("sonic-essay-container");
  const audio = document.getElementById("audio-player");
  const creditsAudio = document.getElementById("credits-audio-player");
  const seekbar = document.getElementById("sonic-essay__seekbar");
  const controls = document.getElementById("sonic-essay__controls");
  const text = document.getElementById("sonic-essay__controls__text");
  const playPause = document.getElementById(
    "sonic-essay__controls__play-pause",
  );
  const volume = document.getElementById("sonic-essay__controls__volume");

  const slides = [...document.querySelectorAll("[data-audio-slide]")].map(
    (element) => {
      return {
        seconds: Number(element.dataset["seconds"]),
        text: element.dataset["text"],
      };
    },
  );

  function segmentFor(seconds) {
    let result = 0;

    for (result; result < slides.length; result++) {
      if (!slides[result + 1]) {
        return result;
      } else {
        if (
          seconds < slides[result + 1].seconds &&
          seconds > slides[result].seconds
        ) {
          return result;
        }
      }
    }
  }

  audio.ontimeupdate = () => {
    const segment = segmentFor(audio.currentTime);
    const slideIndex = segment + 1;
    const startTime = slides[segment].seconds;
    const endTime = slides[segment + 1]
      ? slides[segment + 1].seconds
      : audio.duration;

    const percent =
      ((audio.currentTime - startTime) / (endTime - startTime)) * 100;
    seekbar.style.width = `${percent}%`;

    if (window.se_controller == "time") {
      if (swiper.activeIndex !== slideIndex) {
        if (container.classList.contains("sonic-essay__paused")) {
          audio.pause();
        } else {
          swiper.slideTo(slideIndex);
        }
      }
    }
  };

  audio.onended = () => {
    console.log("ended");
    window.se_controller = "touch";
    swiper.slideNext();
  };

  function attachEvents(swiper) {
    swiper.on("slideChange", () => {
      console.log("slideChange");
      if (
        swiper.activeIndex !== 0 &&
        swiper.activeIndex != swiper.slides.length - 1
      ) {
        const slideData = slides[swiper.activeIndex - 1];
        document.body.classList.add("not-first-slide");

        text.innerText = slideData.text;

        if (window.se_controller == "touch") {
          console.log("touch change");
          audio.currentTime = slideData.seconds;
          audio.play();
        } else {
          console.log("time change");
        }
      } else {
        document.body.classList.remove("not-first-slide");
        audio.pause();
        audio.currentTime = 0;

        if (swiper.activeIndex == swiper.slides.length - 1) {
          creditsAudio.play();
        } else {
          creditsAudio.pause();
        }
      }
    });

    let scrollTimeout;
    swiper.on("scroll", () => {
      console.log("scroll");
      window.se_controller = "touch";

      clearTimeout(scrollTimeout);

      scrollTimeout = setTimeout(function () {
        window.se_controller = "time";
      }, 800);

      if (
        swiper.activeIndex !== 0 &&
        swiper.activeIndex != swiper.slides.length - 1
      ) {
        const slideData = slides[swiper.activeIndex - 1];
        document.body.classList.add("not-first-slide");

        text.innerText = slideData.text;

        console.log("touch change");
        audio.currentTime = slideData.seconds;
        audio.play();
      } else {
        document.body.classList.remove("not-first-slide");
        audio.pause();
        audio.currentTime = 0;

        if (swiper.activeIndex == swiper.slides.length - 1) {
          creditsAudio.play();
        } else {
          creditsAudio.pause();
        }
      }
    });

    swiper.on("touchStart", () => {
      console.log("touchstart");
      window.se_controller = "touch";
    });

    swiper.on("touchEnd", () => {
      setTimeout(function () {
        console.log("touchend");
        window.se_controller = "time";
      }, 200);
    });
  }

  window.restart = () => {
    swiper.slideTo(0);
  };

  window.startPlay = () => {
    if (swiper.allowTouchMove == false) {
      swiper.allowTouchMove = true;
      swiper.mousewheel.enable();
    }

    document.body.classList.add("sonic-essay__started");
    window.se_controller = "time";
    swiper.slideTo(1);
    audio.play();
    controls.classList.add("active");
  };

  window.togglePlay = () => {
    if (container.classList.contains("sonic-essay__paused")) {
      container.classList.remove("sonic-essay__paused");
      audio.play();
    } else {
      container.classList.add("sonic-essay__paused");
    }
  };

  window.toggleMute = () => {
    if (container.classList.contains("sonic-essay__muted")) {
      container.classList.remove("sonic-essay__muted");
      audio.volume = 1;
      creditsAudio.volume = 1;
    } else {
      container.classList.add("sonic-essay__muted");
      audio.volume = 0;
      creditsAudio.volume = 0;
    }
  };
});
