const extractVizId = (url: string): string => {
  const parts = url.split("/");
  return parts.find((item) => item.match(/\d+$/));
};

const extractVizType = (url: string): string => {
  const parts = url.split("/");
  return parts.find((item) => ["visualisation", "story"].indexOf(item) > -1);
};

export { extractVizId, extractVizType };
