import React from "react";
import { createRoot } from "react-dom/client";
import DataHubIndex from "./data-hub-index";

document.addEventListener("DOMContentLoaded", () => {
  const dataHubContainer = document.getElementById("data-hub-app");

  if (dataHubContainer) {
    const node = document.getElementById("tags_data");
    const data = JSON.parse(node.getAttribute("data"));
    createRoot(dataHubContainer).render(<DataHubIndex tagOptions={data} />);
  }
});
