/**
 * On the Contributors page for the Advisors section.
 * A swiper slide for each issue.
 */

document.addEventListener("DOMContentLoaded", function () {
  if (typeof Swiper == "undefined") {
    return;
  }

  var swiper = new Swiper(".contributors__advisors__advisor-container", {
    autoHeight: true,
    pagination: {
      el: ".contributors__advisors__pagination",
      clickable: true,
      renderBullet: function (index, className) {
        var number = index + 1;

        if (number < 10) {
          number = "0" + number;
        }

        return '<span class="' + className + '">' + number + "</span>";
      },
    },
  });
});
