/**
 * Search page
 *
 * TODO: Separate search results into their own component.
 */

import * as React from "react";
import { ChangeEvent, Component } from "react";

interface PrismicRichText {
  text: string;
}

interface PrismicAuthor {
  author: {
    first_name: string;
    last_name: string;
  };
}

interface PrismicImage {
  url: string;
}

interface SearchResult {
  node: {
    _meta: {
      uid: string;
    };
    title?: PrismicRichText[];
    description?: PrismicRichText[];
    featured_image?: PrismicImage;
    authors?: PrismicAuthor[];
  };
}

interface State {
  fetching: boolean;
  query: string;
  searchedQuery: string;
  results: SearchResult[];
}

const displayAuthors = (authors: PrismicAuthor[]): string => {
  const names = authors.map((author, index) => {
    let name;
    try {
      name = `${author.author.first_name} ${author.author.last_name}`;
    } catch (e) {
      console.log(e);
    }
    let prefix = " ";

    if (index > 0) {
      prefix = index == authors.length - 1 ? " and " : ", ";
    }

    return prefix + name;
  });

  return names.join("");
};

export default class SearchIndex extends Component<null, State> {
  constructor(props: null) {
    super(props);

    this.state = {
      fetching: false,
      query: "",
      searchedQuery: "",
      results: [],
    } as State;
  }

  componentDidMount(): void {
    document.getElementById("search-input").focus();

    const params = new URLSearchParams(window.location.search);
    const query = params.get("q");

    if (query && query.length > 0) {
      this.setState(
        {
          query: query,
          searchedQuery: query,
        },
        this.fetchResults,
      );
    }
  }

  updateQuery = (event: ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      query: event.target.value,
    });
  };

  submit = (event) => {
    event.preventDefault();

    this.fetchResults();

    return false;
  };

  fetchResults = () => {
    if (this.state.query) {
      const url = new URL(window.location.href);
      url.searchParams.set("q", this.state.query);
      window.history.pushState({}, "", url);

      this.setState(
        {
          fetching: true,
          searchedQuery: this.state.query,
          results: [],
        },
        () => {
          fetch(`/search_results?q=${this.state.query}`)
            .then((response) => response.json())
            .then((data) => {
              this.setState({
                fetching: false,
                results: data,
              });
            });
        },
      );
    } else {
      window.history.pushState({}, "");

      this.setState({
        fetching: false,
        results: [],
      });
    }
  };

  render() {
    return (
      <div>
        <div className="container body-gutter">
          <form id="search" onSubmit={this.submit}>
            <input
              id="search-input"
              type="search"
              name="q"
              defaultValue={this.state.query}
              onChange={this.updateQuery}
              placeholder="Press enter to search"
            />
          </form>
        </div>

        <section className="search__results">
          <section className="search__results__container">
            {this.state.results.map((item, index) => (
              <a
                className="search__result"
                href={`/articles/${item.node._meta.uid}`}
                key={index}
              >
                <div
                  className="search__result__image"
                  style={{
                    backgroundImage: `url(${item.node.featured_image?.url})`,
                  }}
                ></div>
                <div className="search__result__text text">
                  <h3 className="search__result__title h3">
                    {item.node.title && item.node.title[0]?.text}
                  </h3>
                  <p className="search__result__description descriptive">
                    {item.node.description && item.node.description[0]?.text}
                  </p>
                  <p className="search__result__author">
                    {displayAuthors(item.node.authors)}
                  </p>
                </div>
              </a>
            ))}
            {!this.state.searchedQuery && (
              <div className="search__results--empty">
                Type in a search term above and hit enter to search.
              </div>
            )}
            {this.state.searchedQuery &&
              this.state.results.length == 0 &&
              this.state.fetching == false && (
                <div className="search__results--empty">No results.</div>
              )}
            {this.state.fetching && (
              <div className="search__results--empty">Getting results...</div>
            )}
          </section>
        </section>
      </div>
    );
  }
}
